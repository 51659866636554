<template>
  <div class="tour-overview" v-if="loaded">
    <div class="tour-overview-header">
      <div class="breadcrumbs">
        <div>
          <div class="home-icon">
            <router-link
              :to="{
                name: 'events',
              }"
            >
              <Home />
            </router-link>
            <p class="divider">/</p>
          </div>

          <template v-if="!oneOffEvent">
            <router-link
              class="tour-name"
              :to="{
                name: 'tournamentoverview-standings',
                params: { tourId: eventData.tour.id },
              }"
            >
              <p>Tour overview</p>
            </router-link>
            <p class="divider">/</p>
          </template>

          <p>
            Event
            <template v-if="$route.name == 'public-event-results'">
              results
            </template>
            <template v-if="$route.name == 'public-event-rounds'">
              rounds
            </template>
            <template v-if="$route.name == 'public-event-players'">
              players
            </template>
            <template v-if="$route.name == 'public-event-details'">
              details
            </template>
            <template v-if="$route.name == 'public-event-messages'">
              messages
            </template>
          </p>
        </div>
      </div>
      <div class="left">
        <div class="mobile-banner-info" v-if="eventStatus == 'CLOSED'">
          <template v-if="eventData.isRegistrationOpen == false">
            <div
              class="registration-closed"
              v-if="registrationOpensDate == null"
            >
              <Off />
              <p><strong>Registration closed</strong></p>
            </div>
            <div class="reg-opens" v-else>
              <RegistrationOpen color="sunrise" />
              <p>
                <strong>Registration opens {{ registrationOpensDate }}</strong>
              </p>
            </div>
          </template>

          <template v-if="checkInEnabled">
            <div
              class="checkin-opens"
              v-if="checkInIsInTheFuture && userIsAPlayer"
            >
              <CheckIn />
              <p>
                <strong>Check in opens {{ checkInOpensDate }}</strong>
              </p>
            </div>
            <div
              class="checkin-closes"
              v-if="userOnSite == false && userCanCheckIn"
            >
              <CheckInCloses />
              <p>
                <strong>Check in closes {{ checkInClosesDate }}</strong>
              </p>
            </div>
          </template>
        </div>
        <div class="event-name-header">
          <h2>{{ eventData.name }}</h2>
          <div class="star" @click="toggleFavourite()" v-if="isUserLoggedIn">
            <Star :type="isUserFavouriteEvent ? 'filled' : 'hollow'" />
          </div>
        </div>

        <p class="start-date-mobile">
          {{ formattedEventDate
          }}<template v-if="eventData.city">
            <span class="mx-2">•</span>{{ eventData.city.name }},
            {{ eventData.city.country }}</template
          >
        </p>
        <div class="mobile-event-user-actions">
          <div
            class="finished-event-info-mobile"
            v-if="eventStatus == 'COMPLETED'"
          >
            <span class="status-label strom">Finished</span>
          </div>
          <div class="event-is-live" v-else-if="eventStatus == 'ONGOING'">
            <span class="status-label grass">In progress</span>
          </div>
          <div class="user-status you-are-checked-in" v-else-if="userOnSite">
            <CheckIn color="grass" />
            <p><strong>You’re checked in</strong></p>
          </div>
          <StandardSolidButton
            class="check-in-now"
            v-else-if="userCanCheckIn"
            color="sunrise"
            title="Check in now"
            :fluid="false"
            :desktopFluidity="true"
            @click="checkInUser()"
          />
          <div
            class="user-status you-are-confirmed"
            v-else-if="userRegistrationStatus.status == 'ACCEPTED'"
          >
            <On color="ocean" />
            <p>
              <strong>You’re confirmed</strong>
              <router-link
                v-if="
                  eventData.tour.registrationMethod == 'GROUPS' &&
                  eventData.status == 'CLOSED'
                "
                :to="{ name: 'public-event-group-registration' }"
                class="view-groups"
                >View groups</router-link
              >
              <span
                @click="$refs.unregisterconfirmation.open()"
                v-if="eventData.status == 'CLOSED'"
                >Unregister</span
              >
            </p>
          </div>
          <div
            class="user-status you-are-on-waitinglist"
            v-else-if="userRegistrationStatus.status == 'PENDING'"
          >
            <WaitingList />
            <p>
              <strong
                >You're on the waiting list
                <span @click="$refs.unregisterconfirmation.open()"
                  >Unregister</span
                ></strong
              >
            </p>
          </div>
          <StandardSolidButton
            class="register-now"
            v-else-if="eventData.isRegistrationOpen"
            title="Register"
            :fluid="false"
            :desktopFluidity="true"
            @click="registerToEvent()"
          />
        </div>
        <p class="part-of-tour" v-if="!oneOffEvent">
          Part of {{ eventData.tour.name }} (event {{ eventNumberInTour }} of
          {{ eventData.tour.events.length }})
        </p>
        <div class="event-user-actions" v-if="eventStatus == 'CLOSED'">
          <template v-if="userRegistrationStatus.registered == true">
            <div class="check-in-now" v-if="userOnSite">
              <CheckIn color="grass" />
              <p>
                <strong>You're checked in</strong>
              </p>
            </div>
            <div class="check-in-now" v-else-if="userCanCheckIn">
              <StandardSolidButton
                color="sunrise"
                title="Check in now"
                :fluid="false"
                :desktopFluidity="true"
                @click="checkInUser()"
              />
              <CheckInCloses />
              <p>
                <strong>Check in closes {{ checkInClosesDate }}</strong>
              </p>
            </div>
            <template
              v-else-if="
                userRegistrationStatus.status == 'ACCEPTED' &&
                eventStatus == 'CLOSED'
              "
            >
              <On color="ocean" />
              <p>
                <strong>You're confirmed</strong>
                <router-link
                  v-if="
                    eventData.tour.registrationMethod == 'GROUPS' &&
                    eventData.status == 'CLOSED'
                  "
                  :to="{ name: 'public-event-group-registration' }"
                  class="view-groups"
                  >View groups</router-link
                >
                <span
                  v-if="eventData.status == 'CLOSED'"
                  class="unregister"
                  @click="$refs.unregisterconfirmation.open()"
                  >Unregister</span
                >
              </p>
            </template>
            <template
              v-else-if="
                userRegistrationStatus.status == 'PENDING' &&
                eventStatus == 'CLOSED'
              "
            >
              <WaitingList />
              <p>
                <strong>You're on the waiting list</strong>
                <span
                  class="unregister"
                  @click="$refs.unregisterconfirmation.open()"
                  >Unregister</span
                >
              </p>
            </template>
            <div
              class="check-in-opens"
              v-if="
                checkInIsInTheFuture &&
                userRegistrationStatus.status == 'ACCEPTED'
              "
            >
              <CheckInOpens />
              <p>
                <strong>Check in Opens {{ checkInOpensDate }}</strong>
              </p>
            </div>
          </template>

          <div v-else-if="eventData.isRegistrationOpen">
            <StandardSolidButton
              title="Register"
              :fluid="false"
              :desktopFluidity="true"
              @click="registerToEvent()"
            />
          </div>
          <template v-else>
            <template v-if="registrationOpensDate == null">
              <Off />
              <p><strong>Registration closed</strong></p>
            </template>
            <template v-else>
              <p>
                <RegistrationOpen color="sunrise" /><strong
                  >Registration opens
                  {{ registrationOpensDate }}
                </strong>
              </p>
            </template>
          </template>
        </div>
        <div class="ongoing-event-info" v-if="eventStatus == 'ONGOING'">
          <span class="status-label grass">In progress</span>
        </div>
        <div class="finished-event-info" v-if="eventStatus == 'COMPLETED'">
          <span class="status-label strom">Finished</span>
        </div>
      </div>
      <div class="right">
        <div class="event-date">
          <span class="icon-wrapper">
            <CalendarIcon />
          </span>
          {{ formattedEventDate }}
        </div>
        <div class="next-event" v-if="eventData.city">
          <span class="icon-wrapper">
            <Location />
          </span>
          <p>{{ eventData.city.name }}, {{ eventData.city.country }}</p>
        </div>
        <div class="tags">
          <span class="card-label sunset" v-if="eventData.tour.type">{{
            eventData.tour.type.name
          }}</span>
          <span class="card-label ocean" v-if="eventData.pdgaType">{{
            eventData.pdgaType.name
          }}</span>
        </div>
      </div>

      <nav>
        <ul
          class="tour-nav"
          :class="navClass(unreadMessages.length > 0 ? 'unread' : '')"
        >
          <router-link :to="{ name: 'public-event-results' }">
            <li class="results">Results</li>
          </router-link>
          <router-link :to="{ name: 'public-event-rounds' }">
            <li class="rounds">Rounds</li>
          </router-link>
          <router-link :to="{ name: 'public-event-players' }">
            <li class="players">Players</li>
          </router-link>
          <router-link :to="{ name: 'public-event-details' }">
            <li class="details">Details</li>
          </router-link>
          <router-link :to="{ name: 'public-event-messages' }">
            <li class="message-item">
              Messages
              <span v-if="unreadMessages.length > 0">{{
                unreadMessages.length
              }}</span>
            </li>
          </router-link>
        </ul>
      </nav>
    </div>
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
    <sweet-modal
      class="left-align"
      :enable-mobile-fullscreen="false"
      blocking
      ref="tourpassregmessage"
      hide-close-button
    >
      <h3 class="registrationmessage-title">Register</h3>
      <div
        class="registrationmessage-subtitle"
        @click="showRegistrationMessageInput = !showRegistrationMessageInput"
      >
        <p><b>Add a note</b> (optional)</p>
        <Chevron2 :direction="showRegistrationMessageInput ? 'up' : 'down'" />
      </div>
      <slide-up-down :active="showRegistrationMessageInput" :duration="200">
        <textarea
          rows="4"
          maxlength="250"
          class="user-reg-message"
          v-model="userRegistrationMessage"
        />
      </slide-up-down>
      <div class="buttons">
        <StandardBorderedButton
          title="Cancel"
          :fluid="false"
          @click="$refs.tourpassregmessage.close()"
        />
        <StandardSolidButton
          title="Register"
          :fluid="false"
          @click="tourPassRegistration(userRegistrationMessage)"
        />
      </div>
    </sweet-modal>
    <sweet-modal
      :enable-mobile-fullscreen="false"
      blocking
      ref="unregisterconfirmation"
      hide-close-button
    >
      <h3>Cancel your spot</h3>
      <p>Are you sure you want to remove yourself from this event?</p>

      <div class="buttons">
        <StandardSolidButton
          title="Not now"
          :fluid="false"
          @click="$refs.unregisterconfirmation.close()"
        />
        <StandardBorderedButton
          class="forwards"
          title="Remove me"
          :fluid="false"
          @click="unregister()"
        />
      </div>
    </sweet-modal>
  </div>
</template>

<script>
import moment from "moment";
import Location from "@/assets/icons/Location";
import CalendarIcon from "@/assets/icons/CalendarIcon";
import Home from "@/assets/icons/Home";
import CheckIn from "@/assets/icons/CheckIn";
import CheckInCloses from "@/assets/icons/CheckInCloses";
import CheckInOpens from "@/assets/icons/CheckInOpens";
import WaitingList from "@/assets/icons/WaitingList";
import RegistrationOpen from "@/assets/icons/RegistrationOpen";
import Star from "@/assets/icons/Star";
import On from "@/assets/icons/On";
import Off from "@/assets/icons/Off";
import StandardSolidButton from "@/components/UIElements/StandardSolidButton";
import StandardBorderedButton from "@/components/UIElements/StandardBorderedButton";
import Chevron2 from "@/assets/icons/Chevron2";
import SlideUpDown from "vue-slide-up-down";

export default {
  name: "PublicEvent",
  components: {
    CalendarIcon,
    StandardSolidButton,
    StandardBorderedButton,
    Location,
    On,
    WaitingList,
    CheckInCloses,
    CheckInOpens,
    CheckIn,
    RegistrationOpen,
    Off,
    Home,
    Chevron2,
    SlideUpDown,
    Star,
  },
  data() {
    return {
      loaded: false,
      eventData: null,
      userRegistrationMessage: "",
      showRegistrationMessageInput: false,
    };
  },
  computed: {
    isUserLoggedIn() {
      return this.$store.getters.isUserLoggedIn;
    },
    userFavouriteEvents() {
      return this.$store.getters.favouriteEvents;
    },
    isUserFavouriteEvent() {
      return this.userFavouriteEvents.find(
        (favourite) => favourite.event.id == this.eventData.id
      );
    },
    unreadMessages() {
      if (
        (this.isUserLoggedIn &&
          this.userRegistrationStatus.status == "ACCEPTED") ||
        this.userRegistrationStatus.status == "PENDING"
      ) {
        return this.$store.getters.eventMessages.filter(
          (message) => message.userReadReceipt === null
        );
      } else {
        return [];
      }
    },
    route() {
      return this.$route;
    },
    showRoundsMenu() {
      return (
        this.$route.path.includes("/rounds/") ||
        this.$route.name == "public-event-eventresults"
      );
    },
    userHasValidTourPass() {
      const userId = this.$store.getters.userInfo.sessionInfo.userId;
      const tourPasses = this.$store.getters.tourPasses;
      const tourId = this.eventData.tour.id;

      return (
        tourPasses.find(
          (pass) => pass.tour.id == tourId && pass.payment.isRefunded == false
        ) || null
      );
    },
    userIsAPlayer() {
      const players = this.eventData.players;
      const userId = this.$store.getters.userInfo.sessionInfo.userId;
      let userIsAPlayer = false;

      players.forEach((player) => {
        if (player.userId == userId) {
          userIsAPlayer = true;
        }
      });

      return userIsAPlayer;
    },
    userOnSite() {
      const players = this.eventData.players;
      const userId = this.$store.getters.userInfo.sessionInfo.userId;

      let userOnSite = false;

      players.forEach((player) => {
        if (player.userId == userId && player.onSite) {
          userOnSite = true;
        }
      });

      return userOnSite;
    },
    eventStatus() {
      if (this.eventData.status == "COMPLETED") return "COMPLETED";

      let eventStarted = false;
      this.eventData.rounds.forEach((round) => {
        round.pools.forEach((pool) => {
          if (pool.status == "OPEN" || pool.status == "COMPLETED") {
            eventStarted = true;
          }
        });
      });

      if (eventStarted) return "ONGOING";

      return "CLOSED";
    },
    registrationOpensDate() {
      if (
        moment(this.eventData.registrationOpenAt).isValid() &&
        moment().isBefore(moment(this.eventData.registrationOpenAt))
      ) {
        return moment(this.eventData.registrationOpenAt).format(
          "ddd D MMM [at] HH:mm"
        );
      } else {
        return null;
      }
    },
    checkInClosesDate() {
      return moment(this.eventData.checkInEndsAt).format(
        "ddd D MMM [at] HH:mm"
      );
    },
    checkInOpensDate() {
      return moment(this.eventData.checkInStartsAt).format(
        "ddd D MMM [at] HH:mm"
      );
    },
    userCanCheckIn() {
      const userRegistrationStatus = this.userRegistrationStatus;
      const event = this.eventData;
      let showCheckIn = false;

      if (userRegistrationStatus.status == "ACCEPTED") {
        if (moment().isBetween(event.checkInStartsAt, event.checkInEndsAt)) {
          showCheckIn = true;
        }
      }
      return showCheckIn;
    },
    checkInIsInTheFuture() {
      return moment().isBefore(this.eventData.checkInStartsAt);
    },
    checkInEnabled() {
      return (
        this.eventData.checkInStartsAt !== null &&
        this.eventData.checkInEndsAt !== null
      );
    },
    userRegistrationStatus() {
      const userRegistrations = this.$store.getters.registrations;
      const eventId = this.eventData.id;
      const players = this.eventData.players;
      const userId = this.$store.getters.userInfo.sessionInfo.userId;

      let userRegistrationStatus = {
        registered: false,
        registrationId: null,
        status: null,
        playerId: null,
      };

      if (this.isUserLoggedIn) {
        userRegistrations.forEach((registration) => {
          if (registration.event.id == eventId) {
            userRegistrationStatus.status = registration.status;
            userRegistrationStatus.registrationId = registration.id;

            if (
              registration.status == "PENDING" ||
              registration.status == "ACCEPTED"
            ) {
              userRegistrationStatus.registered = true;
            }
          }
        });

        players.forEach((player) => {
          if (player.userId == userId) {
            userRegistrationStatus.playerId = player.id;
          }
        });
      }

      return userRegistrationStatus;
    },
    formattedEventDate() {
      const startDate = moment(this.eventData.startDate);
      const endDate = moment(this.eventData.endDate);

      if (startDate.isSame(endDate, "day"))
        return startDate.format("ddd D MMM");
      if (startDate.isSame(endDate, "month"))
        return `${startDate.format("D")}-${endDate.format("D MMM")}`;

      return `${startDate.format("D MMM")} - ${endDate.format("D MMM")}`;
    },
    eventNumberInTour() {
      let counter = 0;
      this.eventData.tour.events.forEach((event, index) => {
        if (event.id == this.eventData.id) counter = index + 1;
      });

      return counter;
    },
    oneOffEvent() {
      return this.eventData.tour.events.length > 1 ? false : true;
    },
  },
  methods: {
    toggleFavourite() {
      if (this.isUserFavouriteEvent) {
        this.unMarkEventasFavourite(this.isUserFavouriteEvent.id);
      } else {
        this.markEventasFavourite();
      }
    },
    async unMarkEventasFavourite(favouriteId) {
      try {
        await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
            mutation{
              DeleteFavouriteEvent(favouriteId:"${favouriteId}")
            }
          `,
          },
        });

        this.$store.dispatch("loadFavouriteEvents");
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Poof! Event unstarred.",
          type: "warning",
        });
      } catch (err) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Error",
          type: "Error",
        });
      }
    },
    async markEventasFavourite() {
      try {
        await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
            mutation{
              CreateEventFavourite(eventId:"${this.eventData.id}"){
                id
              }
            }
          `,
          },
        });

        this.$store.dispatch("loadFavouriteEvents");
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Yay! You’ve just starred an event. It’s on your Dashboard.",
          type: "success",
        });
      } catch (err) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Error",
          type: "Error",
        });
      }
    },
    async tourPassRegistration(regMessage) {
      let registrationMessage = "";

      if (regMessage) {
        registrationMessage = ` , message: "${regMessage}"`;
      }

      try {
        let registration = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
            mutation{
              RegisterToEvent(eventId:"${this.$route.params.eventId}",divisionId:"${this.userHasValidTourPass.division.id}" ${registrationMessage}){
                ... on Registration{
                  id
                  status
                }
              }
            }
          `,
          },
        });

        if (registration.data.data.RegisterToEvent != null) {
          this.$store.dispatch("showMessage", {
            show: true,
            message: "You are now registered",
            type: "success",
          });
          this.$refs.tourpassregmessage.close();
          this.loadData();
          this.$store.dispatch("updateUserEventInformation");
        } else {
          this.$refs.tourpassregmessage.close();
          this.$store.dispatch("showMessage", {
            show: true,
            message: "You are already registered to this event",
            type: "error",
          });
        }
      } catch (err) {
        this.$refs.tourpassregmessage.close();
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Something went wrong",
          type: "error",
        });
      }
    },
    navClass(extraClass) {
      let returnValue = extraClass + " ";
      if (this.$route.name == "public-event-results")
        return returnValue + "results";
      if (this.$route.name == "public-event-rounds")
        return returnValue + "rounds";
      if (this.$route.name == "public-event-players")
        return returnValue + "players";
      if (this.$route.name == "public-event-details")
        return returnValue + "details";
      if (this.$route.name == "public-event-messages")
        return returnValue + "messages";

      return "";
    },
    roundPoolFormat(round, roundIndex, poolIndex) {
      let returnString = "";

      if (round.pools.length == 1) {
        returnString = "R" + roundIndex + 1;
      } else {
        returnString = `R${roundIndex + 1}-P${
          this.$store.state.poolConversion[poolIndex]
        }`;
      }

      return returnString;
    },
    goToPool(poolId) {
      if (this.$route.name != "public-event-results") {
        let params = this.$route.params;
        let name = this.$route.name;

        params.poolId = poolId;

        this.$router.push({ name, params });
      } else {
        let params = this.$route.params;
        params.poolId = poolId;

        this.$router.push({ name: "public-event-pool-results", params });
      }
    },
    registerToEvent() {
      if (this.isUserLoggedIn) {
        if (
          this.eventData.tour.registrationMethod == "GROUPS" &&
          this.eventData.status == "CLOSED"
        ) {
          this.$router.push({
            name: "public-event-group-registration",
          });
        } else {
          if (this.userHasValidTourPass) {
            if (this.eventData.tour.allowRegistrationMessage) {
              this.$refs.tourpassregmessage.open();
            } else {
              this.tourPassRegistration();
            }
          } else {
            this.$router.push({
              name: "public-event-checkout",
            });
          }
        }
      } else {
        this.$store.dispatch("setReturnToRouteData", this.$route);
        this.$router.push({
          name: "login",
        });
      }
    },
    async unregister() {
      const userRegistrationId = this.userRegistrationStatus.registrationId;
      try {
        let registration = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
            mutation{
              CancelRegistration(registrationId:"${userRegistrationId}"){
                id
              }
            }
          `,
          },
        });

        this.$refs.unregisterconfirmation.close();
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Spot cancelled",
          type: "success",
        });
        this.$store.dispatch("updateUserEventInformation");
        this.loadData();
      } catch (err) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Error",
          type: "Error",
        });
      }
    },
    async checkInUser() {
      try {
        let checkIn = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
            mutation UpdatePlayer($UpdatePlayer: UpdatePlayerInput!){
              UpdatePlayer(playerId:"${this.userRegistrationStatus.playerId}",input:$UpdatePlayer, asRole:USER){
                id
              }
            }
          `,
            variables: {
              UpdatePlayer: {
                onSite: true,
              },
            },
          },
        });

        this.$store.dispatch("showMessage", {
          show: true,
          message: "Checkin successful",
          type: "success",
        });
        this.loadData();
      } catch (err) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Checkin failed",
          type: "error",
        });
      }
    },
    async loadData() {
      let tourInfo = await this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
          {
            event(eventId: "${this.$router.currentRoute.params.eventId}") {
              id
              name
              status
              startDate
              endDate
              isRegistrationOpen
              registrationOpenAt
              checkInStartsAt
              checkInEndsAt
              eventMessages{
                id
                eventId
                subject
                message
                audience
                createdAt
                updatedAt
                userReadReceipt {
                  id
                  userId
                  messageId
                  readAt
                }
              }
              players{
                userId
                id
                onSite
              }
              rounds{
                id
                pools{
                  id
                  status
                }
              }
              pdgaType {
                type
                name
              }
              city {
                id
                name
                country
              }
              tour {
                id
                name
                registrationMethod
                allowRegistrationMessage
                type {
                  name
                  type
                }
                events {
                  id
                }
              }
            }
          }
          `,
        },
      });
      this.eventData = tourInfo.data.data.event;
      this.$store.dispatch(
        "setEventMessages",
        tourInfo.data.data.event.eventMessages
      );
      this.loaded = true;
    },
  },
  beforeMount() {
    this.loadData();

    if (this.isUserLoggedIn) {
      this.$store.dispatch("loadFavouriteEvents");
    }
    if (this.$route.name != "public-event-players") {
      this.$store.dispatch("updateUserEventInformation");
    }
  },
  mounted() {},
  beforeCreate() {},
  destroyed() {},
};
</script>
<style lang="scss">
.left-align {
  .sweet-modal.is-alert .sweet-content .sweet-content-content {
    text-align: left;
    .registrationmessage-subtitle {
      display: flex;
      align-items: center;
      justify-content: space-between;

      cursor: pointer;
      p {
        font-size: 16px !important;
        margin: 0;
      }
    }

    textarea {
      margin-top: 12px;
      width: 100%;
      border-radius: 6px;
      border: 1px solid $sleet;
      transition: all ease 0.3s;
      padding: 12px 15px;

      &:hover {
        border: 1px solid $midnight;
      }
    }
    .buttons {
      margin-top: 24px !important;
    }
  }
}
@media (min-width: 1200px) {
  .left-align {
    .sweet-modal.is-alert .sweet-content .sweet-content-content {
      .registrationmessage-title {
        margin-bottom: 18px !important;
      }
      .registrationmessage-subtitle {
        font-size: 16px !important;
        display: flex;
      }
      .buttons {
        margin-top: 42px !important;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(10px);
}

.tour-overview-header {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;

  .event-name-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-right: 12px;
    h2 {
      @include Gilroy-Bold;
      font-size: 28px;
      margin: 0;
      display: block;
      padding-right: 12px;
    }

    .star {
      cursor: pointer;
      svg {
        height: 24px;
        width: 24px;
      }
    }
  }

  .breadcrumbs {
    margin: 24px 0 36px 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding: 0 18px;

    .home-icon {
      display: flex;
      align-items: center;
      font-size: 12px;
      svg {
        margin-bottom: 4px;
      }
    }

    div {
      display: flex;
      align-items: center;
      a {
        color: $ocean;
        &:hover {
          text-decoration: underline;
        }
      }
      p {
        font-size: 14px;
        margin: 0;

        &.divider {
          min-width: 12px;
          text-align: center;
        }
      }
    }
  }
  .right {
    display: none;
  }
  .left {
    width: 100%;

    h2,
    p {
      padding-left: 18px;
    }
    .finished-event-info {
      display: none;
    }

    .mobile-event-user-actions {
      padding: 0 18px;
      .check-in-now {
        margin-top: 28px;
      }
      .register-now {
        margin-top: 28px;
      }
      .finished-event-info-mobile {
        margin-top: 16px;
        padding-bottom: 24px;
        position: relative;
        display: flex;
        align-items: center;
        border-bottom: 1px solid $sleet;
        p {
          margin: 0;
          font-size: 14px;
          padding-left: 0;
          margin-left: 8px;
        }
        svg {
          width: 20px;
          height: 20px;
        }
      }
      .event-is-live {
        margin-top: 16px;
        padding-bottom: 24px;
        position: relative;
        display: flex;
        align-items: center;
        border-bottom: 1px solid $sleet;
        p {
          margin: 0;
          font-size: 14px;
          padding-left: 0;
          @include Gilroy-Bold;
        }
      }
      .user-status {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 1px solid $sleet;

        p {
          font-size: 14px;
          margin: 0;
          padding: 0;
          span {
            @include Gilroy-Regular;
            color: $twilight;
            cursor: pointer;
          }
          strong {
            @include Gilroy-Bold;
          }
        }
        svg {
          margin-right: 7px;
        }

        &.you-are-confirmed {
          margin-top: 16px;
          padding-bottom: 24px;
          svg {
            width: 16px;
            height: 16px;
          }
          a {
            margin-left: 8px;
            color: $ocean;

            &:hover {
              text-decoration: underline;
            }
          }
          span {
            margin-left: 8px;
          }
        }
        &.you-are-on-waitinglist {
          margin-top: 16px;
          padding-bottom: 24px;
          svg {
            width: 16px;
            height: 16px;
          }
          span {
            margin-left: 8px;
          }
        }
        &.you-are-checked-in {
          margin-top: 16px;
          padding-bottom: 24px;
          svg {
            width: 16px;
            height: 20px;
          }
          span {
            margin-left: 8px;
          }
        }
      }
    }
    .mobile-banner-info {
      > div {
        margin-bottom: 24px;
        padding: 12px 10px 10px 18px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        p {
          font-size: 14px;
          margin: 0;
          padding: 0;
          strong {
            @include Gilroy-Bold;
          }
        }
        svg {
          margin-right: 10px;
        }
      }

      .registration-closed {
        background: #ffd9e8;
        svg {
          width: 20px;
        }
      }
      .reg-opens {
        background: #fff4d9;
        svg {
          width: 20px;
        }
      }
      .checkin-opens {
        background: #fff4d9;
      }
      .checkin-closes {
        background: #ffe9e3;
        svg {
          width: 18px;
          height: 24px;
        }
      }
    }
    .start-date-mobile {
      margin-top: 12px;
      font-size: 14px;
      margin-bottom: 0;
    }
    .part-of-tour,
    .event-user-actions {
      display: none;
    }

    .ongoing-event-info {
      display: none;
    }
  }

  nav {
    padding: 28px 0 18px 0;
    display: flex;
    width: 100%;
    margin: 0 12px 0 12px;
    max-width: 100%;
    overflow: scroll;

    ul {
      min-width: 350px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 0;
      list-style: none;
      position: relative;

      .router-link-exact-active {
        li {
          position: relative;
          &::after {
            content: "";
            height: 2px;
            background: $midnight;
            position: absolute;
            bottom: -4px;
            left: 0;
            z-index: 3;
            transition: all ease 0.3s;
            width: 100%;
          }
        }
      }
      &.unread {
        .message-item {
          width: 100px;
        }
      }

      li {
        font-size: 16px;
        position: relative;
        display: flex;
        justify-content: center;
        &.results {
          width: 52px;
        }
        &.rounds {
          width: 54px;
        }
        &.players {
          width: 54px;
        }
        &.details {
          width: 52px;
        }

        &.message-item {
          width: 80px;
          position: relative;
          justify-content: flex-start;
          align-items: center;

          span {
            margin-left: 8px;
            right: -6px;
            top: -4px;
            width: 18px;
            height: 18px;
            border-radius: 9px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: $twilight;
            color: white;
            @include Gilroy-Bold;
            font-size: 12px;
          }
        }
      }
    }
  }
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 1200px) {
  .mobile-banner-info {
    display: none;
  }
  .mobile-event-user-actions {
    display: none;
  }
  .tour-overview-header {
    padding: 0;

    .event-name-header {
      h2 {
        font-size: 48px;
      }
      .star {
        svg {
          width: 32px;
          height: 32px;
        }
      }
    }

    .breadcrumbs {
      padding: 50px 0 40px 0;
      border-right: 1px solid $fog;
      width: 75%;
      margin: 0;
      display: flex;
      align-items: center;

      .home-icon {
        svg {
          width: 18px;
          height: auto;
        }
      }
      div {
        a {
          color: $ocean;
        }
        p {
          font-size: 16px;
        }
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding-left: 24px;
      max-width: 25%;

      .icon-wrapper {
        display: flex;
        align-items: center;
        min-width: 30px;
        justify-content: center;
        margin-right: 10px;
      }

      .next-event {
        margin-top: 16px;
        display: flex;
        align-items: center;

        p {
          font-size: 18px;
          margin-bottom: 0;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }

      .event-date {
        display: flex;
        align-items: center;
        font-size: 18px;
      }

      .tags {
        margin-top: 64px;
        display: block;

        span {
          margin-right: 10px;
        }
      }
    }
    .left {
      width: 75%;
      margin-top: 0;
      border-right: 1px solid $fog;
      padding-right: 24px;
      display: flex;
      flex-direction: column;

      h2 {
        padding-left: 0;
      }
      button {
        margin-top: 32px;
      }

      .start-date-mobile {
        display: none;
      }
      .part-of-tour {
        display: block;
        font-size: 18px;
        margin: 8px 0 12px 0;
        padding: 0;
      }
      .finished-event-info {
        margin-top: auto;
        display: flex;
        align-items: center;
        p {
          margin: 0;
          font-size: 18px;
        }
      }
      .ongoing-event-info {
        margin-top: auto;
        display: flex;
        align-items: center;
        p {
          margin: 0;
          font-size: 18px;
        }
      }

      .event-user-actions {
        display: flex;
        align-items: center;
        margin-top: auto;
        p {
          font-size: 16px;
          margin: 0;
          padding: 0;
        }

        .check-in-opens {
          display: flex;
          align-items: center;
          margin-left: 16px;
          p {
            strong {
              @include Gilroy-Bold;
            }
          }
        }

        .check-in-now {
          display: flex;
          align-items: center;
          button {
            margin: 0 32px 0 0;
          }
        }

        .view-groups {
          cursor: pointer;
          color: $ocean;
          margin-left: 10px;
          transition: all ease 0.3s;

          &:hover {
            text-decoration: underline;
          }
        }

        .unregister {
          cursor: pointer;
          color: $twilight;
          margin-left: 10px;
          transition: all ease 0.3s;

          &:hover {
            color: $twilightshade;
          }
        }

        strong {
          @include Gilroy-Bold;
        }
        svg {
          margin-right: 10px;
        }
      }

      button {
        margin-top: 24px;
      }
    }

    nav {
      padding: 0px;
      display: flex;
      width: 100%;
      margin: 48px 0 64px 0;
      border: none;
      overflow: unset;

      &.rounds-left {
        padding-right: 0;
        width: 40%;
      }

      &.rounds-right {
        padding: 0;
        width: 60%;
        ul {
          justify-content: flex-end;
          align-items: center;
          padding: 0;

          .router-link-exact-active {
            li {
              background: $ocean;
              color: white;
              @include Gilroy-Bold;
            }
          }

          li {
            font-size: 16px;
            background: $mist;
            border-radius: 16px;
            padding: 6px 16px;
            margin-right: 12px;
            transition: all ease 0.3s;
            &.active {
              background: $ocean;
              color: white;
              @include Gilroy-Bold;
            }
            &::after {
              display: none;
            }
          }
        }
      }
      ul {
        border-top: 1px solid $fog;
        border-bottom: 1px solid $fog;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        padding: 16px 0 0 0;
        list-style: none;
        &::after {
          content: "";
          height: 3px;
          background: $midnight;
          position: absolute;
          bottom: -2px;
          left: 0;
          z-index: 3;
          transition: all ease 0.3s;
        }

        &.unread {
          .message-item {
            width: 116px;
            left: 0;
          }
        }

        &.results {
          &::after {
            width: 62px;
            left: 0;
          }
        }
        &.rounds {
          &::after {
            width: 64px;
            left: 94px;
          }
        }
        &.players {
          &::after {
            width: 64px;
            left: 190px;
          }
        }
        &.details {
          &::after {
            width: 60px;
            left: 286px;
          }
        }

        &.messages {
          &::after {
            width: 88px;
            left: 378px;
          }
        }

        &.messages {
          &.unread {
            &::after {
              width: 116px;
              left: 378px;
            }
          }
        }

        li {
          font-size: 18px;
          padding-bottom: 16px;
          margin-right: 32px;
          padding: 0 0px 16px 0px;
          &:hover {
            &::after {
              content: "";
              height: 3px;
              background: $sleet;
              position: absolute;
              bottom: -1px;
              left: 0;
              z-index: 2;
              transition: all ease 0.3s;
              width: 100%;
            }
          }

          &.results {
            width: 62px;
            display: flex;
            justify-content: center;
          }
          &.rounds {
            width: 64px;
            display: flex;
            justify-content: center;
          }
          &.players {
            width: 64px;
            display: flex;
            justify-content: center;
          }
          &.details {
            width: 60px;
            display: flex;
            justify-content: center;
          }
          &.message-item {
            width: 88px;
            display: flex;
            justify-content: center;
          }
        }

        .router-link-exact-active {
          li {
            &::after {
              display: none;
            }
          }
        }
      }
    }
  }
}
</style>

