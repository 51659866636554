<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="30"
    viewBox="0 0 22 30"
  >
    <g transform="translate(-490 -148.001)">
      <path
        class="a"
        d="M15,0A10.759,10.759,0,0,0,4,10.5a10.04,10.04,0,0,0,1.01,4.375,57.944,57.944,0,0,0,5.85,8.169A41.707,41.707,0,0,1,15,30a34.18,34.18,0,0,1,4-6.956c2.255-2.774,5.058-6.233,5.99-8.169A10.039,10.039,0,0,0,26,10.5,10.759,10.759,0,0,0,15,0Z"
        transform="translate(486 148.001)"
      />
    </g>
    <g transform="translate(-386.875 -260.375)">
      <g transform="translate(393.875 266.875)">
        <line class="b" x2="11.314" transform="translate(0 8) rotate(-45)" />
        <line class="b" x2="11.314" transform="translate(0 0.001) rotate(45)" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "CheckInCloses",
};
</script>

<style lang="scss" scoped>
.a {
  fill: $sunset;
}
.b {
  fill: none;
  stroke: #fff;
  stroke-width: 2px;
}
</style>