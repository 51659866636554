<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="30"
    viewBox="0 0 22 30"
  >
    <g transform="translate(-490 -148.001)">
      <path
        class="a"
        d="M15,0A10.759,10.759,0,0,0,4,10.5a10.04,10.04,0,0,0,1.01,4.375,57.944,57.944,0,0,0,5.85,8.169A41.707,41.707,0,0,1,15,30a34.18,34.18,0,0,1,4-6.956c2.255-2.774,5.058-6.233,5.99-8.169A10.039,10.039,0,0,0,26,10.5,10.759,10.759,0,0,0,15,0Z"
        transform="translate(486 148.001)"
      />
      <g transform="translate(494.889 153.002)">
        <path
          class="b"
          d="M-589.4,1798.736h-8"
          transform="translate(598.506 -1792.736)"
        />
        <path
          class="b"
          d="M-592.4,1805.235l5-5-5-5"
          transform="translate(597.507 -1794.235)"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "CheckInOpens",
};
</script>

<style lang="scss" scoped>
.a {
  fill: $sunrise;
}
.b {
  fill: none;
  stroke: #fff;
  stroke-miterlimit: 10;
  stroke-width: 2px;
}
</style>